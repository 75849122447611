/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}
body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: 0 10px;
}
body::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("https://storage.googleapis.com/users_medical_files/1710852134911svgviewer-png-output.png");
  background-position: center;
  background-size: cover;
} */
.main{
  background-color: #f9f6f3;
}
html{
  background-color: #f9f6f3;

}
.wrapper {
  max-width: 400px;
  min-width: 300px;
  max-height: 700px;
  width: 30%;
  height: 60%;
  margin: 100px auto;
  background-color: #ffffff;
  border-radius: 25px;
  padding: 30px
}
form {
  display: flex;
  flex-direction: column;
}
h3 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #144b81;
}
.input-field {
  position: relative;
  border-bottom: 2px solid #ccc;
  margin: 15px 0;
}
.input-field label {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  color: #144b81;
  font-size: 16px;
  pointer-events: none;
  transition: 0.15s ease;
}
.input-field input {
  width: 100%;
  height: 40px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  color: #144b81;
}
.input-field input:focus ~ label,
.input-field input:valid ~ label {
  font-size: 0.8rem;
  top: 10px;
  transform: translateY(-120%);
}
.forget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px 0 35px 0;
  color: #144b81;
}
#remember {
  accent-color: #144b81;
}
.forget label {
  display: flex;
  align-items: center;
}
.forget label p {
  margin-left: 8px;
}
.wrapper a {
  color: #144b81;
  text-decoration: none;
}
.wrapper a:hover {
  text-decoration: underline;
}
button {
  background: #144b81;
  color: white;
  font-weight: 600;
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 16px;
  border: 2px solid transparent;
  transition: 0.3s ease;
}
button:hover {
  color: #144b81;
  border-color: #144b81;
  background: rgba(255, 255, 255, 0.15);
}
.register {
  text-align: center;
  margin-top: 30px;
  color: #144b81;
}

#loginform {
  max-width: 500px;
  min-width: 300px;
  max-height: 700px;
  width: 30%;
  height: 60%;
  margin: 100px auto;
  background-color: #ffffff;
  border-radius: 25px;
}

#headerTitle {
  text-align: center;
  font-family: "open sans", sans-serif;
  padding: 2rem 0;
  margin: 0;
  font-size: 2rem;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  max-width: 100%;
}

.row input {
  width: 80%;
  box-sizing: border-box;
  border: none;
  font-size: 1.3rem;
  padding-left: 1.5rem;
  padding-bottom: 1rem;
  box-shadow: inset 0px -3px 0px 0px rgba(187, 187, 187, 0.2);
  transition: box-shadow 0.2s ease-in;
}

.row input:focus {
  box-shadow: inset 0px -3px 0px 0px rgba(34, 193, 195, 0.7);
  outline: none;
}

.row input::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.25s ease-out;
}

.row input:hover::-webkit-input-placeholder,
.row input:focus::-webkit-input-placeholder {
  opacity: 0;
}

.row label {
  align-self: start;
  padding-left: 4.5rem;
  padding-bottom: 0.5rem;
  color: rgba(187, 187, 187, 0.9);
}

.row button {
  border-radius: 25px;
  width: 80%;
  height: 40px;
  font-size: 1.3rem;
  color: white;
  font-weight: 700;
  background: rgb(34, 193, 195);
  background: linear-gradient(
    90deg,
    rgba(34, 193, 195, 1) 0%,
    rgba(253, 187, 45, 1) 100%
  );
  border: 0px;
  cursor: pointer;
  transition: opacity 0.25s ease-out;
}

.row button:hover {
  opacity: 0.8;
}

#button {
  padding-bottom: 1.5rem;
}

#facebookIcon {
  background-image: url("https://image.flaticon.com/icons/svg/145/145802.svg");
  background-repeat: no-repeat;
  width: 3rem;
  height: 3rem;
}

#twitterIcon {
  background-image: url("https://image.flaticon.com/icons/svg/145/145812.svg");
  background-repeat: no-repeat;
  width: 3rem;
  height: 3rem;
}

#googleIcon {
  background-image: url("https://image.flaticon.com/icons/svg/145/145804.svg");
  background-repeat: no-repeat;
  width: 3rem;
  height: 3rem;
}

#iconGroup {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

#iconGroup a {
  margin: 0 0.6rem;
}

#alternativeLogin {
  text-align: center;
  padding-top: 2rem;
  margin-top: 1.5rem;
}
