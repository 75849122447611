/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"); */
 @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
}


/* Global styles */

.xxl-text {
  font-size: 2.8rem;
  letter-spacing: -1.25px;
  line-height: 1.4;
}

.xl-text {
  font-size: 2rem;
  line-height: 1.618;
  letter-spacing: -0.5px;
}
.lg-text {
  font-size: 1.6rem;
  line-height: 1.618;
  letter-spacing: -0.5px;
}

.md-text {
  font-size: 1.2rem;
  line-height: 1.418;
  letter-spacing: -0.5px;
}

.r-text {
  font-size: 1.05rem;
  line-height: 1.618;
  letter-spacing: -0.25px;
}

.sm-text {
  font-size: 0.95rem;
  line-height: 1.618;
  letter-spacing: -0.05px;
}

.xs-text {
  font-size: 0.85rem;
  line-height: 1.618;
  letter-spacing: -0.05px;
}

.xxs-text {
  font-size: 0.8rem;
  line-height: 1.618;
  letter-spacing: -0.05px;
}

.link-text {
  display: inline-block;
}

.inverted-text {
  color: #fff;
}

.ah-op {
  opacity: 0.75;
}

.h-op {
  opacity: 0.5;
}

.q-op {
  opacity: 0.25;
}

.bq-op {
  opacity: 0.15;
}
/* .link-text:hover
{
    text-decoration: underline;
} */

/* Font weight */

.xb-weight {
  font-weight: 700;
}

.b-weight {
  font-weight: 600;
}

.m-weight {
  font-weight: 500;
}

.r-weight {
  font-weight: 400;
}

/* Margin */

/* margin bottom*/

.xxxxl-b-m {
  margin-bottom: 64px !important;
}

.xxxl-b-m {
  margin-bottom: 48px !important;
}

.xxl-b-m {
  margin-bottom: 36px !important;
}

.xl-b-m {
  margin-bottom: 28px !important;
}

.lg-b-m {
  margin-bottom: 20px !important;
}

.r-b-m {
  margin-bottom: 12px !important;
}

.sm-b-m {
  margin-bottom: 8px !important;
}

.xs-b-m {
  margin-bottom: 4px !important;
}

/* margin top*/

.xxxxl-t-m {
  margin-top: 64px !important;
}

.xxxl-t-m {
  margin-top: 48px !important;
}

.xxl-t-m {
  margin-top: 36px !important;
}

.xl-t-m {
  margin-top: 28px !important;
}

.lg-t-m {
  margin-top: 20px !important;
}

.r-t-m {
  margin-top: 12px !important;
}

.sm-t-m {
  margin-top: 8px !important;
}

.xs-t-m {
  margin-top: 4px !important;
}

/* margin left*/

.xxxxl-l-m {
  margin-left: 64px !important;
}

.xxxl-l-m {
  margin-left: 48px !important;
}

.xxl-l-m {
  margin-left: 36px !important;
}

.xl-l-m {
  margin-left: 28px !important;
}

.lg-l-m {
  margin-left: 20px !important;
}

.r-l-m {
  margin-left: 12px !important;
}

.sm-l-m {
  margin-left: 8px !important;
}

.xs-l-m {
  margin-left: 4px !important;
}

/* margin right*/

.xxxxl-r-m {
  margin-right: 64px !important;
}

.xxxl-r-m {
  margin-right: 48px !important;
}

.xxl-r-m {
  margin-right: 36px !important;
}

.xl-r-m {
  margin-right: 28px !important;
}

.lg-r-m {
  margin-right: 20px !important;
}

.r-r-m {
  margin-right: 12px !important;
}

.sm-r-m {
  margin-right: 8px !important;
}

.xs-r-m {
  margin-right: 4px !important;
}

/* padding bottom*/

.xxxxl-b-p {
  padding-bottom: 64px !important;
}

.xxxl-b-p {
  padding-bottom: 48px !important;
}

.xxl-b-p {
  padding-bottom: 36px !important;
}

.xl-b-p {
  padding-bottom: 28px !important;
}

.lg-b-p {
  padding-bottom: 20px !important;
}

.r-b-p {
  padding-bottom: 12px !important;
}

.sm-b-p {
  padding-bottom: 8px !important;
}

.xs-b-p {
  padding-bottom: 4px !important;
}

/* padding top*/

.xxxxl-t-p {
  padding-top: 64px !important;
}

.xxxl-t-p {
  padding-top: 48px !important;
}

.xxl-t-p {
  padding-top: 36px !important;
}

.xl-t-p {
  padding-top: 28px !important;
}

.lg-t-p {
  padding-top: 20px !important;
}

.r-t-p {
  padding-top: 12px !important;
}

.sm-t-p {
  padding-top: 8px !important;
}

.xs-t-p {
  padding-top: 4px !important;
}

/* padding left*/

.xxxxl-l-p {
  padding-left: 64px !important;
}

.xxxl-l-p {
  padding-left: 48px !important;
}

.xxl-l-p {
  padding-left: 36px !important;
}

.xl-l-p {
  padding-left: 28px !important;
}

.lg-l-p {
  padding-left: 20px !important;
}

.r-l-p {
  padding-left: 12px !important;
}

.sm-l-p {
  padding-left: 8px !important;
}

.xs-l-p {
  padding-left: 4px !important;
}

/* padding right*/

.xxxxl-r-p {
  padding-right: 64px !important;
}

.xxxl-r-p {
  padding-right: 48px !important;
}

.xxl-r-p {
  padding-right: 36px !important;
}

.xl-r-p {
  padding-right: 28px !important;
}

.lg-r-p {
  padding-right: 20px !important;
}

.r-r-p {
  padding-right: 12px !important;
}

.sm-r-p {
  padding-right: 8px !important;
}

.xs-r-p {
  padding-right: 4px !important;
}

.center {
  text-align: center;
}

.brand-text {
  color: #124b81;
}
.uppercase {
  text-transform: uppercase;
}

.container {
  max-width: 1280px;
  padding: 120px 40px;
  margin: auto;
}
.sm-container {
  max-width: 1280px;
  padding: 64px 40px;
  margin: auto;
}

.badge {
  font-size: 1rem;
  font-weight: 500;
  color: #124b81;
  display: inline-block;
  margin-bottom: 12px;
  background-color: rgba(18, 75, 129, 0.095);
  border-radius: 24px;
  letter-spacing: -0.15px;
  padding: 8px 16px;
}

.section-header {
  margin-bottom: 48px;
}
.section-header-center {
  margin-bottom: 48px;
  text-align: center;
  margin: auto;
  max-width: 520px;
}

.section-sub-title {
  font-size: 0.85rem;
  color: #124b81;
  font-weight: 600;
  letter-spacing: -0.15px;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.section-title {
  font-size: 2rem;
  letter-spacing: -0.5px;
  font-weight: 700;
  line-height: 1.4;
}

.section-text {
  font-weight: 400;
  font-size: 1.05rem;
  margin-top: 12px;
  line-height: 1.618;
}
/* Navigation */

nav {
  display: block;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  left: 0;
  transition: all 0.25s ease-in-out;
}

.float-nav {
  position: fixed;
  top: 0;
  background-color: #fff;
  /* box-shadow: 0 0.4rem 2.6rem rgba(123,10,10,.05); */
  border-bottom: 1px solid #f6f6f8;
}
.nav-container {
  transition: all 0.5s ease-in-out;
  display: block;
  max-width: 1280px;
  margin: auto;
  padding: 40px 40px;
}

.float-nav .nav-container {
  display: block;
  max-width: 1280px;
  margin: auto;
  padding: 24px 40px !important;
}

.nav-content {
  display: grid;
  grid-template-columns: 25% 50% 25%;
  justify-content: space-between;
  align-items: center;
}

.mob-nav-content {
  display: none;
}

.nav-logo {
  height: 36px;
  width: auto;
}

.nav-link-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.nav-link-container a {
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: -0.15px;
  padding: 12px 16px;
  text-decoration: none;
  color: #3371a2;
  transition: all 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.nav-active-item {
  color: #ff8500 !important;
}
.nav-active-item:hover {
  color: #3371a2 !important;
}
.item a:hover {
  color: #ff8500;
}
.nav-link-container a:hover {
  color: #ff8500;
}
.dark-nav a:hover {
  color: #fff;
}
.dark-nav .nav-link-container a {
  color: #fff;
}
.float-nav .nav-link-container a {
  color: #101010 !important;
}
.nav-btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.nav-login-btn {
  color: #124b81;
  padding: 14px 28px;
  font-weight: 500;
  font-size: 0.9rem;
  letter-spacing: -0.15px;
  border-radius: 5px;
  background-color: rgba(18, 75, 129, 0.1);
  margin-right: 24px;
  text-decoration: none;
}
.nav-signup-btn {
  color: #fff;
  padding: 14px 28px;
  font-weight: 500;
  font-size: 0.9rem;
  border-radius: 5px;
  background-color: #124b81;
  text-decoration: none;
}
.dark-nav .nav-login-btn {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-nav .nav-signup-btn {
  color: #124b81;
  background-color: rgba(255, 255, 255, 1);
}
.float-nav .nav-login-btn {
  color: #124b81 !important;
  background-color: rgba(18, 75, 129, 0.1);
}
.float-nav .nav-signup-btn {
  color: #fff;
  background-color: rgba(18, 75, 129, 1);
}

.form-btn {
  border: 0;
  color: #fff;
  padding: 4px 12px;
  background-color: rgba(18, 75, 129, 1);
  cursor: pointer;
}
.form-btn:hover {
  background-color: rgb(11, 56, 99);
}

.mob-nav-menu-container {
  display: none;
}

.cta-section {
  /* background: linear-gradient(to bottom, #f7f7f8, #f7f9fa); */
}

.cta-container {
  padding: 80px 40px;
  padding-top: 220px;
  position: relative;
  /* min-height: 100vh; */
  max-height: 820px;
  height: 100vh;
  background: white;
}
.cta-grid {
  display: grid;
  grid-template-columns: 45% 55%;
}

.cta-title {
  /* font-size: 2.8rem;
    font-weight: 700;
    letter-spacing: -1.25px;
    line-height: 1.4; */
  /* margin-bottom: 12px; */
}

.cta-text {
  /* font-size: 1.05rem;
    font-weight: 400;
    letter-spacing: -.35px;
    line-height: 1.618; */
}

.cta-img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
}

.cta-btn-container {
  display: flex;
  margin-top: 40px;
}
.cta-btn-container img {
  height: 64px;
  width: auto;
}
.cta-btn-container img:first-child {
  margin-right: 16px;
}

/* Benefit Section */

.benefit-header-grid {
  display: grid;
  grid-template-columns: 40% 50%;
  justify-content: space-between;
}

.benefit-grid {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  margin-top: 80px;
}

.benefit-item {
  text-align: center;
  padding: 0 40px;
  margin-bottom: 88px;
  color: #003e6f;
  /* border-right: 1px solid #efefef; */
}
.benefit-item:last-child {
  border-right: 0;
}

.benefit-img-container {
  background-color: #fff3e4;
  height: 120px;
  width: 120px;
  margin: auto;
  border-radius: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
}

.benefit-img {
  height: 54px;
  margin: auto;
  width: 54px;
}

/* Feature */

.feature-item {
  display: grid;
  grid-template-columns: 50% 50%;
  /* padding: 80px 0; */
  justify-content: space-between;
  align-items: center;
}
.feature-item-flipped {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 80px 0;
  justify-content: space-between;
  align-items: center;
}

.feature-item-content {
  padding-right: 64px;
  max-width: 90%;
}

.feature-img-container {
  display: flex;
  justify-content: center;
  padding: 0 40px;
}
.feature-img {
  height: auto;
  width: 80%;
}

/* Testimonial */

.testimonial-section {
  /* background-color: #f7f7f8; */
}

.testimonial-container {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.testimonal-img-container {
  height: 520px;
  width: 70%;
  background-image: url("./assets/images/testimonial.jpg");
  background-size: cover;
  border-radius: 4px;
  background-position: center;
}

.testimonial-content {
  border-radius: 4px;
  background-color: #124b81;
  position: absolute;
  min-height: 320px;
  padding: 80px 64px;
  left: 0%;
  max-width: 40%;
  top: 10%;
}

.testimonial-btn {
  background-color: #ff8401;
  padding: 12px 16px;
  display: inline-block;
  border: 0;
  color: #fff;
  font-size: 0.95rem;
  font-weight: 500;
  margin-top: 16px;
  border-radius: 4px;
}

/* News Section */

.news-grid {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  border-radius: 4px;
  justify-content: space-between;
}
.news-img-container {
  height: 200px;
  border-radius: 6px;
  background-size: cover;
  width: 100%;
  background-image: url("./assets/images/member-2.jpg");
  position: relative;
  overflow: hidden;
}

.news-img {
  position: absolute;
  width: 100%;
  height: auto;
}

.news-date-content {
  position: absolute;
  left: 12px;
  top: 12px;
  background-color: #fff;
  border-radius: 4px;
  padding: 4px 12px;
}

/* Bottom CTA Section */

.bottom-cta-section {
  background-color: rgba(18, 75, 129, 0.1);
}

.bottom-cta-content {
  text-align: center;
  margin: auto;
  max-width: 520px;
}
.bottom-cta-title {
  font-size: 2rem;
  letter-spacing: -0.5px;
  font-weight: 700;
  line-height: 1.4;
  text-align: center;
  color: #101010;
}

.bottom-cta-btn-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.bottom-cta-btn-container img {
  height: 72px;
  width: auto;
}
.bottom-cta-btn-container img:first-child {
  margin-right: 16px;
}

/* Bottom contact page */

.footer-contact-content {
  max-width: 1080px;
}
.footer-contact-divided-group {
  display: grid;
  grid-template-columns: 45% 45%;
  justify-content: space-between;
  margin-bottom: 24px;
}

.footer-contact-group {
  display: block;
  margin-bottom: 24px;
}

.footer-submit-group {
  display: flex;
  justify-content: flex-start;
}
.footer-submit-group button {
  width: auto;
}
/* Footer */

footer {
  background-color: #082846;
}

.footer-container {
  max-width: 1240px;
  padding: 80px 40px;
  margin: auto;
  display: grid;
  grid-template-columns: 5fr 5fr;
  justify-content: space-between;
}

.footer-link-grid {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;
}

.footer-logo {
  height: 48px;
}

.footer-link-grid h5 {
  font-weight: 400;
  font-size: 0.85rem;
  color: #8898a7;
  margin-bottom: 24px;
}

.footer-link-grid a {
  color: #e3e3e3;
  font-size: 0.85rem;
  font-weight: 400;
  margin-bottom: 20px;
  display: block;
  text-decoration: none;
}

/* Page Header */

.page-header-section {
  background: linear-gradient(to right, #0f3a64, #082846);
}

.page-header-container-divided {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 150px;
  padding-bottom: 120px;
}
.page-header-container {
  display: block;
  padding-top: 240px;
  margin: auto;
  text-align: center;
  max-width: 680px;
}
.page-header-content {
  margin: auto;
  max-width: 100%;
}

.page-header-badge {
  font-size: 0.9rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  margin-bottom: 12px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  letter-spacing: -0.25px;
  padding: 8px 16px;
}

.page-header-title {
  font-size: 2rem;
  letter-spacing: -0.5px;
  font-weight: 600;
  color: #fff;
  line-height: 1.4;
}

.page-header-text {
  font-weight: 300;
  font-size: 1.05rem;
  margin-top: 12px;
  color: #c3c3c3;
  line-height: 1.618;
}

.page-header-img-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
}

/* News Page */

.recent-news-section .container {
  border-bottom: 1px solid #e3e3e3;
}
.recent-news-grid {
  display: grid;
  grid-template-columns: 45% 45%;
  justify-content: space-between;
  row-gap: 40px;
  /* padding-bottom: 24px; */
}
.recent-news-img {
  background-image: url("./assets/images/member-2.jpg");
  width: 160px;
  height: 160px;
  border-radius: 8px;
  margin-right: 12px;
  /* flex: 1; */
}
.recent-news-item {
  display: grid;
  grid-template-columns: 1fr 2fr;
  /* margin-top: 40px; */
}
.recent-news-item:hover {
  cursor: pointer;
}
.recent-news-item:hover .recent-news-item-content {
  color: #124b81 !important;
}

.recent-news-item-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Case Studies Page */

.expanded-case-study-item {
  display: grid;
  grid-template-columns: 50% 45%;
  justify-content: space-between;
}
.expanded-case-study-img {
  width: 100%;
  background-color: #151515;
  height: 100%;
  object-fit: cover;

}
.case-study-grid {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  justify-content: space-between;
}

.case-study-item {
  display: block;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  overflow: hidden;
}
.case-study-img {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 180px;
}
.case-study-img img {
  position: absolute;
  width: 100%;
  height: auto;
}
.case-study-content {
  padding: 24px;
}

.case-study-btn {
  border-radius: 4px;
  background-color: #ff8401;
  font-size: 0.85rem;
  font-weight: 500;
  color: #fff;
  letter-spacing: -0.25px;
  display: inline-block;
  cursor: pointer;
  padding: 8px 12px;
  margin-top: 12px;
  border: 0;
}

.case-study-btn:hover {
  background-color: #e17705;
}
/* About Page */

.about-bg {
  height: auto;
  /* margin: auto; */
  /* position: absolute; */
  width: 85%;
  margin-bottom: -180px;
}

.vision-grid {
  display: grid;
  grid-template-columns: 48% 45%;
  justify-content: space-between;
}

.vision-point {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.vision-points-img {
  height: 36px;
  width: auto;
  margin-right: 18px;
}
.vision-img-container {
  padding: 40px;
}

.vision-text {
  font-size: 1.05rem;
  line-height: 1.618;
  font-weight: 500;
  letter-spacing: -0.15px;
}

.vision-img {
  width: 100%;
  height: auto;
}

.team-grid {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  margin-top: 48px;
}

.team-grid-item {
  text-align: center;
  padding: 40px 40px;
}

.member-image-container {
  margin-bottom: 18px;
  /* height: 240px;
    width: 240px;
    border-radius: 120px; */
}

.member-image {
  height: auto;
  width: 100%;
  border-radius: 50%;
}
.member-name {
  font-size: 1.05rem;
  font-weight: 500;
}
.member-field {
  font-size: 0.9rem;
  letter-spacing: -0.15px;
  font-weight: 400;
  color: #757575;
}
/* FAQ Page */

.faq-item-active {
}

.faq-list-content {
  max-width: 920px;
  margin: auto;
}

.faq-item {
  border-bottom: 1px solid #e3e3e3;
  padding: 24px 0;
  overflow: hidden;
  padding-left: 48px;
  transition: height 0.35s ease-in-out;
}
.faq-item:hover {
  background-color: #fcfdff;
  cursor: pointer;
}

.faq-question-content p {
  /* font-size: 1.05rem;
    font-weight: 600;
    letter-spacing: -.35px; */
  transition: height 0.35s ease-in-out;
  height: 0%;
}
.faq-item .faq-answer-content {
  transition: height 0.35s ease-in-out;
  /* height: 0px; */
}
.faq-item-active .faq-answer-content {
  transition: height 0.35s ease-in-out;
  height: 100%;
  max-height: 240px;
}
.faq-item .faq-answer-content p {
  /* margin-top: 18px;
    font-size: 1.05rem;
    opacity: 0;
    font-weight: 400;
    line-height: 1.618;
    transition: height .35s ease-in-out;
    letter-spacing: -.35px; */
  height: 100%;
  display: none;
}
.faq-item-active .faq-answer-content p {
  /* margin-top: 18px;
    font-size: 1.05rem;
    opacity: 1;
    font-weight: 400;
    line-height: 1.618;
    transition: height .35s ease-in-out;
    letter-spacing: -.35px; */
  height: 100%;
  display: block;
}
.faq-answer-content p {
  margin-top: 18px;
  color: #454545;
  font-size: 1.05rem;
  opacity: 1;
  font-weight: 400;
  line-height: 1.618;
  letter-spacing: -0.35px;
  height: auto;
}

/* Contact us */

.form-content {
  max-width: 480px;
  margin: auto;
}

.input-group {
  display: block;
  margin-bottom: 32px;
}

label {
  font-size: 0.95rem;
  font-weight: 500;
  color: #757575;
  display: block;
  letter-spacing: -0.15px;
  margin-bottom: 8px;
}

.text-input {
  background-color: #f7f7f8;
  border-radius: 4px;
  padding: 16px 16px;
  border: 0;
  border: 1px solid #e3e3e3;
  outline: none;
  width: 100%;
}

.text-input:focus,
.message-input:focus {
  border: 1px solid #124b81;
}

.message-input {
  background-color: #f7f7f8;
  border-radius: 4px;
  padding: 16px 16px;
  resize: none;
  height: 200px;
  width: 100%;
  border: 0;
  border: 1px solid #e3e3e3;
  outline: none;
  width: 100%;
}

.form-btn {
  background-color: #124b81;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  padding: 18px 16px;
  border: 0;
  outline: none;
  width: 100%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .cta-img {
    position: absolute;
    bottom: 0;
    right: 12%;
    width: 90%;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 767px) {
  /* Mobile Global styles */

  .xxl-text {
    font-size: 1.8rem;
    letter-spacing: -0.75px;
    line-height: 1.4;
  }

  .xl-text {
    font-size: 1.4rem;
    line-height: 1.618;
    letter-spacing: -0.5px;
  }
  .lg-text {
    font-size: 1.2rem;
    line-height: 1.618;
    letter-spacing: -0.5px;
  }

  .md-text {
    font-size: 1rem;
    line-height: 1.418;
    letter-spacing: -0.35px;
  }

  .r-text {
    font-size: 0.95rem;
    line-height: 1.618;
    letter-spacing: -0.15px;
  }

  .sm-text {
    font-size: 0.85rem;
    line-height: 1.618;
    letter-spacing: -0.15px;
  }

  .xs-text {
    font-size: 0.75rem;
    line-height: 1.618;
    letter-spacing: -0.15px;
  }

  .xxs-text {
    font-size: 0.7rem;
    line-height: 1.618;
    letter-spacing: -0.05px;
  }

  .link-text {
    display: inline-block;
  }

  .inverted-text {
    color: #fff;
  }

  .ah-op {
    opacity: 0.75;
  }

  .h-op {
    opacity: 0.5;
  }

  .q-op {
    opacity: 0.25;
  }

  .bq-op {
    opacity: 0.15;
  }
  /* .link-text:hover
    {
        text-decoration: underline;
    } */

  /* Font weight */

  .xb-weight {
    font-weight: 700;
  }

  .b-weight {
    font-weight: 600;
  }

  .m-weight {
    font-weight: 500;
  }

  .r-weight {
    font-weight: 400;
  }

  /* Margin */

  /* margin bottom*/

  .xxxxl-b-m {
    margin-bottom: 64px !important;
  }

  .xxxl-b-m {
    margin-bottom: 48px !important;
  }

  .xxl-b-m {
    margin-bottom: 36px !important;
  }

  .xl-b-m {
    margin-bottom: 28px !important;
  }

  .lg-b-m {
    margin-bottom: 20px !important;
  }

  .r-b-m {
    margin-bottom: 12px !important;
  }

  .sm-b-m {
    margin-bottom: 8px !important;
  }

  .xs-b-m {
    margin-bottom: 4px !important;
  }

  /* margin top*/

  .xxxxl-t-m {
    margin-top: 64px !important;
  }

  .xxxl-t-m {
    margin-top: 48px !important;
  }

  .xxl-t-m {
    margin-top: 36px !important;
  }

  .xl-t-m {
    margin-top: 28px !important;
  }

  .lg-t-m {
    margin-top: 20px !important;
  }

  .r-t-m {
    margin-top: 12px !important;
  }

  .sm-t-m {
    margin-top: 8px !important;
  }

  .xs-t-m {
    margin-top: 4px !important;
  }

  /* margin left*/

  .xxxxl-l-m {
    margin-left: 64px !important;
  }

  .xxxl-l-m {
    margin-left: 48px !important;
  }

  .xxl-l-m {
    margin-left: 36px !important;
  }

  .xl-l-m {
    margin-left: 28px !important;
  }

  .lg-l-m {
    margin-left: 20px !important;
  }

  .r-l-m {
    margin-left: 12px !important;
  }

  .sm-l-m {
    margin-left: 8px !important;
  }

  .xs-l-m {
    margin-left: 4px !important;
  }

  /* margin right*/

  .xxxxl-r-m {
    margin-right: 64px !important;
  }

  .xxxl-r-m {
    margin-right: 48px !important;
  }

  .xxl-r-m {
    margin-right: 36px !important;
  }

  .xl-r-m {
    margin-right: 28px !important;
  }

  .lg-r-m {
    margin-right: 20px !important;
  }

  .r-r-m {
    margin-right: 12px !important;
  }

  .sm-r-m {
    margin-right: 8px !important;
  }

  .xs-r-m {
    margin-right: 4px !important;
  }

  /* padding bottom*/

  .xxxxl-b-p {
    padding-bottom: 64px !important;
  }

  .xxxl-b-p {
    padding-bottom: 48px !important;
  }

  .xxl-b-p {
    padding-bottom: 36px !important;
  }

  .xl-b-p {
    padding-bottom: 28px !important;
  }

  .lg-b-p {
    padding-bottom: 20px !important;
  }

  .r-b-p {
    padding-bottom: 12px !important;
  }

  .sm-b-p {
    padding-bottom: 8px !important;
  }

  .xs-b-p {
    padding-bottom: 4px !important;
  }

  /* padding top*/

  .xxxxl-t-p {
    padding-top: 64px !important;
  }

  .xxxl-t-p {
    padding-top: 48px !important;
  }

  .xxl-t-p {
    padding-top: 36px !important;
  }

  .xl-t-p {
    padding-top: 28px !important;
  }

  .lg-t-p {
    padding-top: 20px !important;
  }

  .r-t-p {
    padding-top: 12px !important;
  }

  .sm-t-p {
    padding-top: 8px !important;
  }

  .xs-t-p {
    padding-top: 4px !important;
  }

  /* padding left*/

  .xxxxl-l-p {
    padding-left: 64px !important;
  }

  .xxxl-l-p {
    padding-left: 48px !important;
  }

  .xxl-l-p {
    padding-left: 36px !important;
  }

  .xl-l-p {
    padding-left: 28px !important;
  }

  .lg-l-p {
    padding-left: 20px !important;
  }

  .r-l-p {
    padding-left: 12px !important;
  }

  .sm-l-p {
    padding-left: 8px !important;
  }

  .xs-l-p {
    padding-left: 4px !important;
  }

  /* padding right*/

  .xxxxl-r-p {
    padding-right: 64px !important;
  }

  .xxxl-r-p {
    padding-right: 48px !important;
  }

  .xxl-r-p {
    padding-right: 36px !important;
  }

  .xl-r-p {
    padding-right: 28px !important;
  }

  .lg-r-p {
    padding-right: 20px !important;
  }

  .r-r-p {
    padding-right: 12px !important;
  }

  .sm-r-p {
    padding-right: 8px !important;
  }

  .xs-r-p {
    padding-right: 4px !important;
  }

  .center {
    text-align: center;
  }

  .brand-text {
    color: #124b81;
  }
  .uppercase {
    text-transform: uppercase;
  }

  .container {
    max-width: 1280px;
    padding: 40px 24px;
    margin: auto;
  }

  .sm-container {
    max-width: 1280px;
    padding: 24px 24px;
    margin: auto;
  }

  .badge {
    font-size: 0.8rem;
    font-weight: 500;
    color: #124b81;
    display: inline-block;
    margin-bottom: 8px;
    background-color: rgba(18, 75, 129, 0.1);
    border-radius: 24px;
    letter-spacing: -0.25px;
    padding: 6px 12px;
  }

  .section-header {
    margin-bottom: 48px;
  }
  .section-header-center {
    margin-bottom: 48px;
    text-align: center;
    margin: auto;
    max-width: 520px;
  }

  .section-sub-title {
    font-size: 0.85rem;
    color: #124b81;
    font-weight: 600;
    letter-spacing: -0.15px;
    text-transform: uppercase;
    margin-bottom: 12px;
  }

  .section-title {
    font-size: 2rem;
    letter-spacing: -0.5px;
    font-weight: 700;
    line-height: 1.4;
  }

  .section-text {
    font-weight: 400;
    font-size: 1.05rem;
    margin-top: 12px;
    line-height: 1.618;
  }
  /* Navigation */

  nav {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999;
    left: 0;
    transition: all 0.5s ease-in-out;
  }

  .float-nav {
    position: fixed;
    top: 0;
    background-color: #fff;
    /* box-shadow: 0 0.4rem 2.6rem rgba(123,10,10,.05); */
    border-bottom: 1px solid #f6f6f8;
    transition: all 0.5s ease-in-out;
  }
  .nav-container {
    transition: all 0.5s ease-in-out;
    display: block;
    max-width: 1280px;
    margin: auto;
    padding: 18px 24px;
  }

  .float-nav .nav-container {
    display: block;
    max-width: 1280px;
    margin: auto;
    padding: 18px 24px !important;
  }

  .nav-container {
    display: block;
    max-width: 1280px;
    margin: auto;
    padding: 24px 24px;
  }

  .nav-content {
    display: none;
  }

  .nav-logo {
    height: 36px;
    width: auto;
  }

  .mob-nav-logo {
    height: 24px;
    width: auto;
  }

  .mob-nav-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-menu-icon {
    height: 24px;
    width: auto;
  }

  .nav-link-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .nav-link-container a {
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: -0.15px;
    padding: 12px 16px;
    text-decoration: none;
    color: #151515;
  }
  .dark-nav .nav-link-container a {
    color: #fff;
  }
  .nav-btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .nav-login-btn {
    color: #124b81;
    padding: 14px 28px;
    font-weight: 500;
    font-size: 0.9rem;
    letter-spacing: -0.15px;
    border-radius: 5px;
    background-color: rgba(18, 75, 129, 0.1);
    margin-right: 24px;
    text-decoration: none;
  }
  .nav-signup-btn {
    color: #fff;
    padding: 14px 28px;
    font-weight: 500;
    font-size: 0.9rem;
    border-radius: 5px;
    background-color: #124b81;
    text-decoration: none;
  }
  .dark-nav .nav-login-btn {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
  }
  .dark-nav .nav-signup-btn {
    color: #124b81;
    background-color: rgba(255, 255, 255, 1);
  }
  .float-nav .nav-btn-container .nav-login-btn {
    color: #124b81 !important;
    background-color: rgba(18, 75, 129, 0.1) !important;
  }
  .float-nav .nav-signup-btn {
    color: #fff !important;
    background-color: #124b81 !important;
  }

  .mob-nav-menu-container {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0.4rem 2.6rem rgba(123, 10, 10, 0.05);
    padding-top: 80px;
    position: absolute;
    height: auto;
    z-index: -1;
    left: 0;
    top: -100vh;
    width: 100%;
    opacity: 0;
    display: block;
    transition: all 0.35s ease-in-out;
  }
  .mob-nav-menu-container a {
    color: #101010;
    border-bottom: 1px solid #f7f7f8;
    text-decoration: none;
    font-size: 0.95rem;
    font-weight: 500;
    letter-spacing: -0.35px;
    display: block;
    padding: 24px 24px;
  }

  .mob-nav-active {
    display: block;
    opacity: 1;
    top: 0;
  }

  .mob-nav-menu-container .nav-btn-container {
    display: flex;
    justify-content: space-between;
    padding: 40px 24px;
  }

  .mob-nav-menu-container .nav-login-btn {
    color: #124b81;
    padding: 16px 48px;
    font-weight: 500;
    font-size: 0.9rem;
    letter-spacing: -0.15px;
    border-radius: 5px;
    background-color: rgba(18, 75, 129, 0.1);
    margin-right: 24px;
    width: 100%;
    text-align: center;
    text-decoration: none;
  }
  .mob-nav-menu-container .nav-signup-btn {
    text-align: center;
    color: #fff;
    padding: 16px 48px;
    width: 100%;
    font-weight: 500;
    font-size: 0.9rem;
    border-radius: 5px;
    background-color: #124b81;
    text-decoration: none;
  }
  .dark-nav .nav-login-btn {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
  }
  .dark-nav .nav-signup-btn {
    color: #124b81;
    background-color: rgba(255, 255, 255, 1);
  }

  /* Home page */

  .cta-section {
    background: linear-gradient(to bottom, #f7f7f8, #f7f9fa);
  }

  .cta-container {
    padding: 80px 24px;
    padding-top: 120px;
    position: relative;
    min-height: 820px;
  }
  .cta-grid {
    display: block;
    grid-template-columns: 45% 55%;
  }

  .cta-title {
    /* font-size: 2.8rem;
        font-weight: 700;
        letter-spacing: -1.25px;
        line-height: 1.4; */
    /* margin-bottom: 12px; */
  }

  .cta-text {
    /* font-size: 1.05rem;
        font-weight: 400;
        letter-spacing: -.35px;
        line-height: 1.618; */
  }

  .cta-img {
    position: absolute;
    bottom: 0;
    right: 12%;
    width: 80%;
  }

  .cta-btn-container {
    display: flex;
    margin-top: 40px;
  }
  .cta-btn-container img {
    height: 48px;
    width: auto;
  }
  .cta-btn-container img:first-child {
    margin-right: 8px;
  }

  /* Benefit Section */

  .benefit-header-grid {
    display: block;
  }

  .benefit-grid {
    display: grid;
    grid-template-columns: 48% 48%;
    justify-content: space-between;
    margin-top: 24px;
  }

  .benefit-item {
    text-align: center;
    padding: 24px 16px;
    border-right: 0;
    margin-bottom: 0;
  }
  .benefit-item:last-child {
    border-right: 0;
  }

  .benefit-img-container {
    background-color: #fff3e4;
    height: 80px;
    width: 80px;
    margin: auto;
    border-radius: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }

  .benefit-img {
    height: 40px;
    margin: auto;
    width: 40px;
  }

  /* Feature */

  .feature-item {
    display: block;
    grid-template-columns: 50% 50%;
    padding: 48px 0;
    justify-content: space-between;
    align-items: center;
  }
  .feature-item-flipped {
    display: flex;
    flex-direction: column-reverse;
    padding: 48px 0;
    justify-content: space-between;
    align-items: center;
  }

  .feature-item-content {
    padding-right: 0px;
  }

  .feature-img-container {
    display: flex;
    justify-content: center;
    padding: 0 40px;
    padding-top: 24px;
  }
  .feature-img {
    height: auto;
    width: 100%;
  }

  /* Testimonial */

  /* Testimonial */

  .testimonial-section {
    /* background-color: #f7f7f8; */
  }

  .testimonial-container {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-bottom: 200px;
  }

  .testimonal-img-container {
    height: 280px;
    width: 100%;
    background-image: url("./assets/images/testimonial.jpg");
    background-size: cover;
    background-position: center;
  }

  .testimonial-content {
    background-color: #124b81;
    position: absolute;
    min-height: 120px;
    padding: 24px 24px;
    left: 5%;
    max-width: 90%;
    top: 90%;
  }

  .testimonial-btn {
    background-color: #ff8401;
    padding: 12px 16px;
    display: inline-block;
    border: 0;
    color: #fff;
    font-size: 0.95rem;
    font-weight: 500;
    margin-top: 16px;
    border-radius: 4px;
  }

  /* News Section */

  .news-grid {
    display: block;
    grid-template-columns: 30% 30% 30%;
    border-radius: 4px;
    justify-content: space-between;
  }
  .news-img-container {
    height: 120px;
    border-radius: 6px;
    background-size: cover;
    width: 100%;
    background-image: url("./assets/images/member-2.jpg");
    position: relative;
    overflow: hidden;
  }

  .news-img {
    position: absolute;
    width: 100%;
    height: auto;
  }

  .news-date-content {
    position: absolute;
    left: 12px;
    top: 12px;
    background-color: #fff;
    border-radius: 4px;
    padding: 4px 12px;
  }

  /* Bottom CTA Section */

  .bottom-cta-section {
    background-color: rgba(18, 75, 129, 0.1);
  }

  .bottom-cta-content {
    text-align: center;
    margin: auto;
    max-width: 520px;
  }
  .bottom-cta-title {
    font-size: 2rem;
    letter-spacing: -0.5px;
    font-weight: 700;
    line-height: 1.4;
    text-align: center;
    color: #101010;
  }

  .bottom-cta-btn-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .bottom-cta-btn-container img {
    height: 40px;
    width: auto;
  }
  .bottom-cta-btn-container img:first-child {
    margin-right: 16px;
  }

  /* Footer */

  footer {
    background-color: #082846;
  }

  .footer-container {
    max-width: 1240px;
    padding: 40px 24px;
    margin: auto;
    display: block;
    grid-template-columns: 5fr 5fr;
    justify-content: space-between;
  }

  .footer-link-grid {
    display: grid;
  grid-template-columns: 25% 25% 50%;

    justify-content: space-between;
  }

  .footer-logo {
    height: 48px;
    margin-bottom: 64px;
  }

  .footer-link-grid h5 {
    font-weight: 400;
    font-size: 0.85rem;
    color: #8898a7;
    margin-bottom: 24px;
  }
  .footer-link-grid div {
    margin-bottom: 40px;
  }

  .footer-link-grid a {
    color: #e3e3e3;
    font-size: 0.85rem;
    font-weight: 400;
    margin-bottom: 20px;
    display: block;
    text-decoration: none;
  }

  /* Page Header */

  .page-header-section {
    background: linear-gradient(to right, #0f3a64, #124b81);
  }

  .page-header-container-divided {
    display: block;
    padding-top: 120px;
    padding-bottom: 0px;
  }
  .page-header-container {
    display: block;
    padding-top: 140px;
    margin: auto;
    text-align: center;
    max-width: 680px;
  }
  .page-header-content {
    margin: auto;
    max-width: 100%;
  }

  .page-header-badge {
    font-size: 0.9rem;
    font-weight: 500;
    color: #fff;
    display: inline-block;
    margin-bottom: 12px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 24px;
    letter-spacing: -0.25px;
    padding: 8px 16px;
  }

  .page-header-img-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 100%;
  }

  /* News Page */

  .recent-news-section .container {
    border-bottom: 1px solid #e3e3e3;
  }
  .recent-news-grid {
    display: block;
    /* padding-bottom: 24px; */
  }
  .recent-news-img {
    background-image: url("./assets/images/member-2.jpg");
    width: 80px;
    height: 80px;
    border-radius: 8px;
    /* flex: 1; */
  }
  .recent-news-item {
    display: grid;
    grid-template-columns: 25% 70%;
    justify-content: space-between;
    margin-bottom: 24px;
    /* margin-top: 40px; */
  }
  .recent-news-item:hover {
    cursor: pointer;
  }
  .recent-news-item:hover .recent-news-item-content {
    color: #124b81 !important;
  }

  .recent-news-item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  /* Case Studies Page */

  .expanded-case-study-item {
    display: block;
    grid-template-columns: 50% 45%;
    justify-content: space-between;
  }
  .expanded-case-study-img {
    width: 100%;
    background-color: #151515;
    height: 200px;
    margin-bottom: 16px;
    object-fit: cover;
  }
  .case-study-grid {
    display: block;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-between;
  }

  .case-study-item {
    display: block;
    border: 1px solid #e3e3e3;
    border-radius: 6px;
    overflow: hidden;
  }
  .case-study-img {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 180px;
  }
  .case-study-img img {
    position: absolute;
    width: 100%;
    height: auto;
  }
  .case-study-content {
    padding: 24px;
  }

  .case-study-btn {
    border-radius: 4px;
    background-color: #ff8401;
    font-size: 0.85rem;
    font-weight: 500;
    color: #fff;
    letter-spacing: -0.25px;
    display: inline-block;
    cursor: pointer;
    padding: 8px 12px;
    margin-top: 12px;
    border: 0;
  }

  .case-study-btn:hover {
    background-color: #e17705;
  }

  /* About Page */

  .about-bg {
    height: auto;
    /* margin: auto; */
    /* position: absolute; */
    width: 100%;
    margin-bottom: 0;
    margin-bottom: 0;
    transform: translateY(40px);
  }

  .vision-grid {
    display: block;
  }

  .vision-point {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  .vision-points-img {
    height: 28px;
    width: auto;
    margin-right: 12px;
  }

  .team-grid {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 24px;
  }

  .team-grid-item {
    text-align: center;
    padding: 24px 24px;
  }

  .member-image-container {
    margin-bottom: 18px;
    /* height: 240px;
        width: 240px;
        border-radius: 120px; */
  }

  .member-image {
    height: auto;
    width: 100%;
    border-radius: 50%;
  }
  .member-name {
    font-size: 1.05rem;
    font-weight: 500;
  }
  .member-field {
    font-size: 0.9rem;
    letter-spacing: -0.15px;
    font-weight: 400;
    color: #757575;
  }
  /* FAQ Page */

  .faq-item-active {
  }

  .faq-list-content {
    max-width: 920px;
    margin: auto;
  }

  .faq-item {
    border-bottom: 1px solid #e3e3e3;
    padding: 24px 0;
    overflow: hidden;
    padding-left: 24px;
    transition: height 0.35s ease-in-out;
  }
  .faq-item:hover {
    background-color: #fcfdff;
    cursor: pointer;
  }

  .faq-question-content p {
    /* font-size: 1.05rem;
        font-weight: 600;
        letter-spacing: -.35px; */
    transition: height 0.35s ease-in-out;
    height: 0%;
  }
  .faq-item .faq-answer-content {
    transition: height 0.35s ease-in-out;
    /* height: 0px; */
  }
  .faq-item-active .faq-answer-content {
    transition: height 0.35s ease-in-out;
    height: 100%;
    max-height: 240px;
  }
  .faq-item .faq-answer-content p {
    /* margin-top: 18px;
        font-size: 1.05rem;
        opacity: 0;
        font-weight: 400;
        line-height: 1.618;
        transition: height .35s ease-in-out;
        letter-spacing: -.35px; */
    height: 100%;
    display: none;
  }
  .faq-item-active .faq-answer-content p {
    /* margin-top: 18px;
        font-size: 1.05rem;
        opacity: 1;
        font-weight: 400;
        line-height: 1.618;
        transition: height .35s ease-in-out;
        letter-spacing: -.35px; */
    height: 100%;
    display: block;
  }
  .faq-answer-content p {
    margin-top: 18px;
    color: #454545;
    font-size: 1.05rem;
    opacity: 1;
    font-weight: 400;
    line-height: 1.618;
    letter-spacing: -0.35px;
    height: auto;
  }

  /* Contact us */

  .form-content {
    max-width: 480px;
    margin: auto;
  }

  .input-group {
    display: block;
    margin-bottom: 20px;
  }

  label {
    font-size: 0.75rem;
    font-weight: 500;
    color: #757575;
    display: block;
    letter-spacing: -0.05px;
    margin-bottom: 8px;
  }

  .text-input {
    background-color: #f7f7f8;
    border-radius: 4px;
    padding: 16px 16px;
    border: 0;
    border: 1px solid #e3e3e3;
    outline: none;
    width: 100%;
  }

  .text-input:focus,
  .message-input:focus {
    border: 1px solid #124b81;
  }

  .message-input {
    background-color: #f7f7f8;
    border-radius: 4px;
    padding: 16px 16px;
    resize: none;
    height: 120px;
    width: 100%;
    border: 0;
    border: 1px solid #e3e3e3;
    outline: none;
    width: 100%;
  }

  .form-btn {
    background-color: #124b81;
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    font-size: 0.85rem;
    padding: 12px 16px;
    border: 0;
    outline: none;
    width: 100%;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
.mainNav {
  background: white;
  position: fixed;
}
.footer-copy-container {
  display: flex;
  justify-content: center;
  /* margin-bottom: 20px; */
  color: white;
}
