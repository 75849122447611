.contactus-img{
    width: 429.89px;
height: 837.22px;
top: 497.79px;
left: 7.95px;
opacity: 0.1;
/* angle: 55.81 deg; */

}
.secHeader{
    display: flex;
    justify-content: center;
    font-size: 2rem;
    color:#144B81;
}