/* Move the breadcrumbs to the bottom */
.chonky-breadcrumbs-container {
  order: 1; /* Ensure breadcrumbs appear after file list */
}

/* Other optional styles */
.chonky-breadcrumbs {
  background-color: #f5f5f5; /* Light background for breadcrumbs */
  padding: 8px;
  border-radius: 4px;
}
.chonky-navbarBreadcrumbs {
  margin-top: 9vh !important; /* Add some spacing above */
}

.fg-active {
  background-color: red !important;
}
.MuiBreadcrumbs-ol {
  margin-top: 7vh !important;
  z-index: 1 !important;
}

.chonky-file-list-item.chonky-dir-item .chonky-file-item-icon { /* Target folder icons specifically */
  color: #ff5722 !important; /* Your desired color with !important */
}
.MuiButton-text{
  color: #124b81 !important;
}
.MuiButton-label{
  color: #124b81 !important;

}
.chonky-infoContainer{
  color: #124b81 !important;

}